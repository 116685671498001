// extracted by mini-css-extract-plugin
export var azure = "#3c86a5";
export var blanco = "#fff";
export var cloud = "#687077";
export var container = "DateRef-module--container--cdb5d";
export var coral = "#cb5e65";
export var date = "DateRef-module--date--15506";
export var dateContent = "DateRef-module--dateContent--502d3";
export var defaultBorder = "10px";
export var forest = "#799884";
export var item = "DateRef-module--item--37202";
export var jetBlack = "#14171c";
export var lightBase = "getColor(lightBase)";
export var lightdarkBase = "getColor(lightdarkBase)";
export var masOscuro = "#171926";
export var midnight = "#383e5f";
export var minimal = "#f4f4f8";
export var mist = "#b8c3d0";
export var mobileWidth = "760px";
export var onyx = "#191c21";
export var oscuro = "#2d3346";
export var purple = "#9264e9";
export var raven = "#1e2329";
export var steelBlue = "#2b313c";
export var title = "DateRef-module--title--5d7e6";